*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  overflow: hidden;
  font-family: sans-serif;
  
}
*::selection{
  background-color: #F98226;
  color: #161c24;
}